<template>
  <div class="urgent-event-card">
    <div class="wrap-photos" @click="showEventDetail()">
      <div class="time">
        <span>{{ detectTime }}</span> 
      </div>
      <div class="photos">
        <div class="snapshot">
          <img v-lazy="event.snapshotUrl">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('history_channel')/*鏡頭*/" placement="left">
            <img v-if="event.channelId === 0" class="camera" src="@/assets/icons/camera1-m.svg" alt="">
            <img v-else-if="event.channelId === 1" class="camera" src="@/assets/icons/camera2-m.svg" alt="">
          </el-tooltip>
        </div>
        <div v-if="urgMode === 'chased'" class="photo">
          <img v-lazy="event.photoUrl">
        </div>
      </div>
    </div>
    <div v-if="urgMode === 'chased'" class="info">
      <div class="number-index">
        <div class="number">
          <img src="@/assets/icons/Car_L_yellow.svg" alt="">
          <span>{{ event.triggered[0].content }}</span>
        </div>
        <div>
          <TooltipElement :content="String(eventNo)">
          <div class="index">{{ eventNo }}</div>
          </TooltipElement>
        </div>
      </div>
      <div class="content">
        <div class="items">
          <div class="item">
            <TooltipElement :content="$t('event_chasing')/*圍捕*/" alwaysShow>
              <img src="@/assets/icons/urgent-s.svg" alt="">
            </TooltipElement >
            <span>{{ $t('event_chasing')/*圍捕*/ }}</span>
          </div>
          <div class="item video-title">
            <TooltipElement :content="$t('history_device')/*辨識設備*/" alwaysShow>
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="getEventTitleId(event.user.id)">
              <span>{{ getEventTitleId(event.user.id) }}</span>
            </TooltipElement>
          </div>

          <div v-if="event.linkedUserName" class="item linkedUser">
            <TooltipElement :content="$t('history_link_user') /*勾稽使用者*/" alwaysShow>
              <img src="@/assets/icons/user.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="`${ event.linkedUserName }(${ event.linkedUserAccount })`">
              <span>{{ `${ event.linkedUserName }(${ event.linkedUserAccount })` }}</span>
            </TooltipElement>
          </div>
          
          <div v-if="event.triggered[0].tag.length > 0" class="item tags">
            <TooltipElement :content="$t('history_tag'/*標記*/)" alwaysShow>
              <img src="@/assets/icons/tag.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="getTagNameStr(event.triggered[0].tag, tagList)" alwaysShow>
              <div class="wrap-tags">
                <span v-for="(tag, index) in getTagNameList(event.triggered[0].tag, tagList)" :key="index">{{ tag }}</span>
              </div>
            </TooltipElement>
          </div>

          <div v-if="event.missionCode > 0 || event.missionNotice" class="line"></div>

          <div v-if="event.missionCode > 0" class="item">
            <TooltipElement :content="$t('mission')/*任務*/" alwaysShow>
              <img :src="getMissionIcon(event.missionCode)" alt="">
            </TooltipElement >
            
            <span>{{ codeBooks.mission[event.missionCode] }}
              <span v-if="event.missioned === 3">{{ $t('finish')/*完成*/ }}</span>
            </span>
          </div>

          <div v-if="event.missionNotice" class="item push-message">
            <TooltipElement :content="$t('history_push_message')/*推送訊息*/" alwaysShow>
              <img src="@/assets/icons/note.svg" alt="">
            </TooltipElement >
            <TooltipElement :content="event.missionNotice">
              <span>{{ event.missionNotice }}</span>
            </TooltipElement>
          </div>
        </div>
        <div class="icons">
          <TooltipElement :content="$t('history_fr_open_google_map'/*開啟Google map*/)" alwaysShow>
            <img src="@/assets/icons/google-map-icon.png" alt="" @click="goGoogleMap(event.gps.latitude, event.gps.longitude)">
          </TooltipElement>
          <TooltipElement :content="$t('search_misjudgment'/*誤判*/)" alwaysShow>
            <img :src="getMisjudgedIcon(event.misjudged)" alt="">
          </TooltipElement>
        </div>
      </div>
    </div>
    <div v-else-if="urgMode === 'sos'" class="info">
      <div class="number-index">
        <div class="number">
          <span></span>
        </div>
        <div>
          <TooltipElement :content="String(eventNo)">
          <div class="index">{{ eventNo }}</div>
          </TooltipElement>
        </div>
      </div>
      <div class="content">
        <div class="items">
          <div class="item video-title">
            <TooltipElement :content="$t('history_device')/*辨識設備*/" alwaysShow>
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="getEventTitleId(event.userAccount)">
              <span>{{ getEventTitleId(event.userAccount) }}</span>
            </TooltipElement>
          </div>

          <div v-if="event.linkedUserName" class="item linkedUser">
            <TooltipElement :content="$t('history_link_user') /*勾稽使用者*/" alwaysShow>
              <img src="@/assets/icons/user.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="`${ event.linkedUserName }(${ event.linkedUserAccount })`">
              <span>{{ `${ event.linkedUserName }(${ event.linkedUserAccount })` }}</span>
            </TooltipElement>
          </div>
        </div>
        <div class="icons">
          <TooltipElement :content="$t('history_fr_open_google_map'/*開啟Google map*/)" alwaysShow>
            <img src="@/assets/icons/google-map-icon.png" alt="" @click="goGoogleMap(event.gps.latitude, event.gps.longitude)">
          </TooltipElement>
        </div>
      </div>
    </div>
    <div class="map">
      <EventMap :event="event" :type="urgMode" :centerShift="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import moment from 'moment'
import { getAge } from '@/utils/lib.js'
import { 
  getMisjudgedIcon, 
  getTagNameStr, 
  getTagNameList,
  getMissionIcon,
  goGoogleMap 
} from '@/utils/index.js'

export default {
  name: 'UrgentEventCard',
  components: {
    EventMap: () => import('@/components/Base/EventMap.vue'),
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
  },
  props: ['event', 'index'],
  data() {
    return {
      age: '年紀',
      isShowTooltip: false,
    }
  },
  computed: {
    ...mapState(['tagList', 'codeBooks']),
    ...mapState('historyUrgent', ['urgMode', 'urgCurrentPage', 'urgPageSize']),
    ...mapGetters(['timezone', 'getEventTitleId']),
    eventNo() {
      return (this.urgCurrentPage - 1) * this.urgPageSize + this.index + 1
    },
    detectTime() {
      const time = this.urgMode === 'chased' 
        ? this.event.detectTime 
        : this.event.gps.timestamp ? this.event.gps.timestamp : this.event.startTime
      return moment(time).tz(this.timezone).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalShow']),
    ...mapMutations('historyUrgent', ['updateUrgCurrentIndex']),
    getAge,
    getMisjudgedIcon,
    getTagNameStr,
    getTagNameList,
    getMissionIcon,
    goGoogleMap,
    showEventDetail() {
      this.updateUrgCurrentIndex(this.index)
      this.updateHistoryEventModalShow(true)
    },
  }
}
</script>

<style lang="scss" scoped>
.urgent-event-card {
  width: 100%;
  height: 100%;
  background: #4A5C7880;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &:hover {
    background: #4A5C78cc;
  }
  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 100%;
    background: #F94144;
    left: 0;
    top: 0;
    border-radius: 8px 0 0 8px;
  }
}

.wrap-photos {
  padding: 12px 12px 12px 24px;
  .time {
    margin-bottom: 12px;
  }
  .time span {
    display: inline-block;
    color: #FFFFFF;
    background: #282942;
    border-radius: 17px;
    padding: 5px 28px;
    font-size: px2rem(18);
    line-height: 24px;
  }

  .photos {
    display: flex;
    column-gap: 8px;
    .snapshot, .photo {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
    }

    .snapshot {
      position: relative;
      width: px2rem(210);
      min-height: px2rem(138);
      background: #ffffff1a;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      img[lazy=error] {
        width: 60%;
        height: 60%;
      }
      
      & > .camera {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 28px;
        height: 28px;
      }
    }
    .photo {
      width: px2rem(150);
      background: #ffffff1a; 
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      img[lazy=error] {
        width: 60%;
        height: 60%;
      }
      span {
        display: block;
        color: #FFFFFF80;
      }
    }
  }
  .score {
    font-size: 20px;
    color: #ffffff80;
    margin-left: 155px;
  }
}

.info {
  width: calc(100% - px2rem(746));
  height: 100%;
  padding: px2rem(12) px2rem(16) px2rem(12) px2rem(8);
  box-sizing: border-box;
  .number-index {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    column-gap: 18px;
    .number {
      flex: 1;
      display: flex;
      align-items: center;
      line-height: 40px;
      color: #FFE99F;
      img {
        margin-right: 8px;
      }
      span {
        font-size: px2rem(24);
        font-weight: 500;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    .index {
      min-width: 41px;
      max-width: 116px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: px2rem(20);
      text-align: center;
      line-height: 28px;
      font-weight: normal;
      color: #282942;
      background: #FFE99F;
      padding: 0 8px;
      border-radius: 17px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .content {
    height: calc(100% - 45px);
    display: flex;
    column-gap: 20px;
    line-height: 35px;
    color: #ffffff;
    .items {
      width: calc(100% - 50px);
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        &.push-message {
          align-items: flex-start;
          img {
            margin-top: 9px;
          }
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        span {
          font-size: px2rem(20);
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background: #ffffff33;
        margin: 1px 0 6px 0;
      }
    }
    .icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      &.unknown {
        justify-content: flex-end;
      }
    }
  }
}

.tags {
  overflow: hidden;
  .wrap-tags {
    width: 100%;
    height: 35px;
    display: flex;
    margin-bottom: 2px;
    span {
      background: #ffffff33;
      font-size: px2rem(18);
      padding: 0 12px;
      margin-right: 8px;
      border-radius: 18px;
      white-space: nowrap;
    }
  }
}

.video-title, .linkedUser, .push-message {
  width: 100%;
  display: flex;
  span {
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.map {
  width: px2rem(420);
  height: 100%;
  background: #4A5C7880;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30%;
  }
}
</style>